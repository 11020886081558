<template>
  <div>
    <div>
      <el-form :inline="true" class="search">

        <el-form-item label="关键字">
          <el-input v-model="searchData.name" placeholder="提成人或备注搜索"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable style="width: 120px" v-model="searchData.status" placeholder="状态">
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getTcPerson()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="success" @click.stop="clickCreate()">添加提成人</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="name" label="名称"/>
      <el-table-column prop="balance" label="余额"/>
      <el-table-column prop="total" label="总金额"/>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="clickDestroy(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getTcPerson()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑提成人" width="80%" append-to-body>
      <el-form
          ref="person"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.data.name" placeholder="收款人"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formData.data.remark" placeholder="备注信息，必填"/>
        </el-form-item>
      </el-form>

      <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

  </div>
</template>

<script>
import {apiGetTcPersonIndex, apiCreateTcPerson, apiUpdateTcPerson} from "@/api/tcApi";
import {getCompanyId} from "@/plugins/company"

let formData = {
  name: '',
  remark: '',
  company_id: getCompanyId(),
}

export default {
  created() {
    this.getTcPerson()
  },
  data() {
    return {
      searchData:{
        status: 1,
        name: '',
        page: 1,
        per_page: 15,
      },
      pageData:{
        total: 0
      },
      projects:[],
      tableData:[],
      formData:{
        lock: false,
        data: formData,
        rules: {
          name: [{
            required: true,
            message: '请输入收款人姓名',
            trigger: 'blur',
          }],
        },
        show: false,
      },
    }
  },
  methods:{
    getTcPerson () {
      apiGetTcPersonIndex(this.searchData).then(res =>{
        this.pageData = res.meta
        this.tableData = res.data
      })
    },
    // 点击创建
    clickCreate () {
      this.initDialog()
      this.showDialog()
    },
    // 点击更新
    clickUpdate (row) {
      this.formData.data = row
      this.showDialog()
    },
    onSubmit()  {
      if (this.formData.lock) {return;}
      this.formLock()
      this.$refs['person'].validate(valid => {
        if (valid) {
          // 更新逻辑
          if (this.formData.data.id > 0) {
            apiUpdateTcPerson(this.formData.data.id, this.formData.data).then(() => {
              this.$message.success('修改成功')
              this.getTcPerson()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          } else {
            apiCreateTcPerson(this.formData.data).then(() => {
              this.$message.success('创建成功')
              this.getTcPerson()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          }
        }
      })
    },
    // 初始化弹出
    initDialog () {
      this.formData.data = formData
      this.closeDialog()
    },
    // 显示弹窗
    showDialog() {
      this.formData.show = true
    },
    // 关闭弹窗
    closeDialog() {
      this.formData.show = false
    },
    formLock() {
      this.formData.lock = true
    },
    formUnlock() {
      this.formData.lock = false
    },
  }
}
</script>
